 
.header-md::after{
    background: transparent;
}
h1{
    font-weight: 700;
}
ion-title{
    margin-left: 0;
    padding-left: 0;
}

ion-card-title{
    font-size: 15px;
    font-weight: bold;
    color: var(--ion-color-primary);
}
ion-card-subtitle{
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.db-page-header{
    background: var(--ion-color-primary);
}

.ion-color-pagebg{
    background: #f0f4fb !important;
}

ion-card{
    box-shadow: 0 0 4px rgba(26, 76, 150, 0.548);
}
.ion-color-dbcard{
    background: #e9e1ff;
    color: #2c1857;
    box-shadow: none;
    border-radius: 10px;
}